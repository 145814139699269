<template>
  <v-card class="mx-auto my-12" max-width="800" v-if="indoors.length">
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <div
      class="d-flex px-4"
      style="
        align-items: center;
        justify-content: space-between;
        border-bottom: solid 1px #e8e8e8;
      "
    >
      <h3><span v-if="projectView"></span>{{ name }}</h3>
      <v-img :src="this.logo" max-width="60"></v-img>
    </div>
    <v-card-text class="pa-4">
      <v-row v-for="(indoor, index) in indoors" :key="index">
        <v-col cols="12" sm="3" md="3" class="pa-5">
          <v-img :src="indoor.image" class="pa-4" contain></v-img>
        </v-col>
        <v-col cols="12" sm="9" md="9" class="pa-5">
          Zone: {{indoor.name}}
          <span v-show="indoor.lineSet > 0"> | Line Set: {{indoor.lineSet}} Ft.</span>
          <hr/>
          <tbody style="width: 100%; display: table">
            <tr v-for="(val, prop) in indoor.specs" :key="prop">
              <td>
                {{ prop }}
              </td>
              <td class="text-right">
                <strong v-if="val.toString().includes('http')"><a :href="val" target="_blank">View</a></strong>
                <strong v-else>{{ val }}</strong>
              </td>
            </tr>
          </tbody>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" md="3" class="pa-5">
          <v-img :src="outdoor.image" class="pa-4" contain></v-img>
        </v-col>
        <v-col cols="12" sm="9" md="9" class="pa-5">
          Outdoor
          <span v-show="outdoor.lineSet > 0"> | Line Set: {{outdoor.lineSet}} Ft.</span>
          <hr/>
          <tbody style="width: 100%; display: table">
            <tr v-for="(val, prop) in outdoor.specs" :key="prop">
              <td>
                {{ prop }}
              </td>
              <td class="text-right">
                <strong v-if="val.toString().includes('http')"><a :href="val" target="_blank">View</a></strong>
                <strong v-else>{{ val }}</strong>
              </td>
            </tr>
          </tbody>
        </v-col>
      </v-row>
      <v-alert
        :color="charge < 0 ? 'error' : 'primary'"
        dense
        outlined
        type="info"
      >Total Additional System Refrigerant Charge: {{charge}} oz</v-alert>
    </v-card-text>

    <v-card-actions>
      <v-btn v-if="projectView" color="primary" @click="generatePdf" :loading="loadingPdf" :disabled="loadingPdf">Generate PDF</v-btn>
      <v-spacer></v-spacer>
      <v-btn v-if="!projectView" color="primary" @click="selectProduct"
        >Select</v-btn
      >
      <v-btn v-else color="primary" @click="edit">Edit</v-btn>
      <v-btn v-if="system.id" color="error" outlined @click="remove">Delete</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import products from "@/data/productsWebsite.json";
import productsBrochure from "@/data/multiProBrochure.json";
import { mapGetters } from "vuex";
import { sortSpecs, calculateCharge, liquidPipeChargeValueOverride } from "@/helpers";

export default {
  name: "MultiProStandardProductCard",
  props: ["system", "projectView"],
  data() {
    return {
      type: "MultiPRO Standard",
      loadingPdf: false,
      product: null,
      indoors: [],
      outdoor: {},
      charge: null,
      logo: "",
      name: "",
    };
  },
  async created() {
    const outdoorModel = this.system.outdoor;
    const indoors = this.system.indoors;

    this.indoors = indoors.map((obj) => {
      const model = obj.model;
      const indoor = products.find((p) => {
        return (p.Model || "").includes(model.slice(0, -2));
      });
      const indoorSpecs = productsBrochure.find((p) => {
        return p["Indoor Model No."] === model;
      });
      const specs = liquidPipeChargeValueOverride(this.type, indoorSpecs, obj.zone.lineSet, outdoorModel);
      return { image: indoor["Image URL"], specs: sortSpecs(specs, this.systemDocumentation), name: obj.zone.name, lineSet: obj.zone.lineSet };
    });
    const outdoorOne = products.find((p) => {
      return (p.Model || "").includes(outdoorModel);
    });
    const outdoorTwo = products.find((p) => {
      return (p.Model || "").includes(outdoorModel.slice(0, -1));
    });
    const outdoorThree = products.find((p) => {
      return (p.Model || "").includes(outdoorModel.slice(0, -2));
    });
    const outdoor = outdoorOne || outdoorTwo || outdoorThree;
    const outdoorSpecs = productsBrochure.find((p) => {
      return p["Outdoor Model No."] === outdoorModel;
    });
    this.name = outdoor["Product Name"].replace('MultiPRO', 'MultiPRO Standard');
    this.logo = outdoor["Image URL"].replace("feature-1", "logo");
    const outdoorImageUrl = (outdoorModel.includes('24') || outdoorModel.includes('28'))
      ? outdoor["Image URL"]
      : outdoor["Image URL"].replace("feature-1", "feature-2");
    const specs = liquidPipeChargeValueOverride(this.type, outdoorSpecs, this.system.outdoorLineSet, outdoorModel);
    this.outdoor = { image: outdoorImageUrl, specs: sortSpecs(specs, this.systemDocumentation), lineSet: this.system.outdoorLineSet };

    this.charge = await calculateCharge(this.type, this.indoors, this.outdoor)
  },
  computed: {
    ...mapGetters({
      systemDocumentation: "systemDocumentation"
    }),
  },
  methods: {
    async selectProduct() {
      const urlParams = new URLSearchParams(window.location.search);
      const systemId = urlParams.get('systemId');

      this.$store.dispatch("saveSystem", {
        system: { ...this.system },
        type: this.type,
        projectId: this.$route.params.projectId,
        systemId: systemId
      });
    },
    edit() {
      this.$router.push(
        `/project/${this.$route.params.projectId}/builder?systemId=${this.system.id}`
      );
    },
    remove() {
      this.$store.dispatch("deleteSystem", {
        systemId: this.system.id
      });
    },
    async generatePdf() {
      const projectId = this.$route.params.projectId;
      const systemId = this.system.id;

      const data = {
        projectId: projectId,
        systemId: systemId,
        type: this.type,
        name: this.name,
        indoors: this.indoors,
        outdoor: this.outdoor,
        logo: this.logo
      }

      this.loadingPdf = true;
      await this.$store.dispatch("openPdf", data);
      this.loadingPdf = false;
    },
  },
};
</script>
