<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1">Zones</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 2" step="2">
        Zone Options
      </v-stepper-step>
      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 3" step="3">
        Selection
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content
        step="1"
        style="overflow-y: scroll; max-height: 700px"
      >
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="6" md="6" class="py-2" offset-sm="3">
              <v-select
                :items="zoneOptions"
                name="zones"
                label="Number of Zones"
                outlined
                v-model="zoneCount"
              ></v-select>
              <div v-show="zoneCount > 0">
                <label class="d-block mb-2 grey--text text--darken-1 body-2">
                  Outdoor to {{outdoorToLabel}}.
                  <span v-show="outdoorLineSetMax">(Max {{outdoorLineSetMax}} Ft.)</span>
                </label>
                <v-text-field
                  label="Line Set Length"
                  value=""
                  outlined
                  type="number"
                  v-model="outdoorLineSet"
                ></v-text-field>
              </div>
              <div class="text-center">
                <v-btn color="primary" @click="createZones">
                  Continue
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-stepper-content>

      <v-stepper-content
        step="2"
        style="overflow-y: scroll; max-height: 700px"
      >
        <v-container fluid>
          <v-row>
            <v-col
              v-for="(zone, ix) in zones"
              :key="ix"
              cols="12"
              sm="6"
              md="6"
              class="py-2"
            >
              <v-card dense color="grey lighten-5 elevation-2">
                <v-card-text>
                  <v-combobox
                    name="zonename"
                    placeholder="Zone Name"
                    :items="zoneNames"
                    outlined
                    v-model="zone.name"
                    dense
                  >
                  </v-combobox>
                  <v-select
                    :items="unitOptions"
                    name="btu"
                    label="Unit Type"
                    outlined
                    v-model="zone.unitType"
                    v-show="zone.name"
                    dense
                  ></v-select>
                  <v-select
                    :items="btuOptions[zone.unitType]"
                    name="btu"
                    label="BTU"
                    outlined
                    v-model="zone.btu"
                    v-show="zone.name && zone.unitType"
                    dense
                  ></v-select>
                  <div v-show="zone.btu && zones.length > 1">
                    <label class="d-block mb-2">
                      {{outdoorToLabel}} to {{zone.unitType}}.
                      <span v-show="indoorLineSetMax">(Max {{indoorLineSetMax}} Ft.)</span>
                    </label>
                    <v-text-field
                      label="Line Set Length"
                      value=""
                      outlined
                      type="number"
                      v-model="zone.lineSet"
                    ></v-text-field>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-for="msg in elevationAlerts" :key="msg.replace(/ /g, '')">
            <v-col class="text-center">
              <v-alert
                :color="'primary'"
                dense
                outlined
                type="info"
                class="mb-0"
              >{{msg}}</v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <v-btn text class="mr-2" @click="e1 = 1">Back</v-btn>
              <v-btn color="primary" @click="findSystem">Continue</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-stepper-content>
      <v-stepper-content
        step="3"
        style="overflow-y: scroll; max-height: 700px; background: #f4f4f4"
      >
        <div class="text-center mt-5">
          <v-btn color="primary" @click="e1 = 2">back</v-btn>
        </div>
        <v-row>
          <v-col cols="12" sm="6" md="6" class="py-2 mt-5" offset-sm="3">
            <v-select
              v-if="filters.energy.length > 1"
              v-model="filters.energyActive"
              :items="filters.energy"
              item-text="text"
              item-value="value"
              attach
              chips
              label="Filter by Energy Efficiency"
              multiple
              @change="findSystem"
            ></v-select>
            <div class="text-center mt-3">Systems Found: {{systems.length}}</div>
            <v-alert
              class="mt-5"
              border="top"
              colored-border
              color="warning"
              type="error"
              elevation="1"
              v-show="!systems.length"
            >
              {{noResultsMessage}}
            </v-alert>
          </v-col>
        </v-row>
        <div v-for="system in systems" :key="system.key">
          <MultiProUltraProductCard :system="system"></MultiProUltraProductCard>
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import fb from "@/main";
import { groupBy, uniq, sumBy, maxBy } from "lodash";
import { calculateCapacity } from "@/helpers";
import multiProProducts from "@/data/multiProUltraBuilder.json";
import MultiProUltraProductCard from "@/components/MultiProUltraProductCard";
import multiProBrochure from "@/data/multiProBrochure.json";

export default {
  name: "MultiProUltra",
  components: { MultiProUltraProductCard },
  data() {
    return {
      e1: 1,
      type: null,
      unitOptions: [],
      btuOptions: {},
      zoneNames: ['Basement', 'Bedroom', 'Dining Room', 'Kitchen', 'Living Room', 'Office', 'Room', 'Zone'],
      zoneOptions: [1, 2, 3, 4, 5, 6, 7, 8],
      zoneCount: null,
      zones: [],
      systems: [],
      outdoorLineSet: 10,
      outdoorLineSetMax: null,
      indoorLineSet: 10,
      indoorLineSetMax: null,
      noResultsMessage: "Sorry, we don't have a system that supports the combination of your selected indoor capacities. Please revise and try again.",
      filters: {
        energy: [],
        energyActive: []
      },
    };
  },
  watch: {
    zoneCount: function(val, oldVal) {
      if (val !== oldVal) {
        this.outdoorLineSetMax = val === 1 ? 213 : null;
        this.indoorLineSetMax = val === 1 ? 50 : null;
        this.btuOptions = val === 1 ? multiProProducts.btuOptions.single : multiProProducts.btuOptions.multi;
        this.unitOptions = val === 1 ? Object.keys(multiProProducts.btuOptions.single) : Object.keys(multiProProducts.btuOptions.multi);
      }
    }
  },
  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    const systemId = urlParams.get('systemId');
    if (systemId) {
      const doc = await fb.db
        .collection("systems")
        .doc(systemId)
        .get();
      const system = doc.data();
      const zones = system.system.indoors.map((indoor) => {return indoor.zone;});
      if (system.type == 'MultiPRO Ultra') {
        this.zones = zones;
        this.zoneCount = zones.length;
        this.outdoorLineSet = system.system.outdoorLineSet;
        this.e1 = 2;
      }
    }
  },
  computed: {
    outdoorToLabel() {
      if (!this.zoneCount || this.zoneCount == 1) {
        return "Indoor";
      }
      if (this.zoneCount == 2) {
        return "Y-Branch"
      }
      return "Header"
    },
    elevationAlerts() {
      const alerts = [];
      for (let i = 0; i < this.zones.length; i++) {
        for (let j = i + 1; j < this.zones.length; j++) {
          if (i != j && Number(this.zones[i].lineSet) + Number(this.zones[j].lineSet) > 49) {
            alerts.push("The max height difference between indoor units is 49'.");
          }
        }
      }
      const indoorLineSetTotal = sumBy(this.zones, z => Number(z.lineSet));
      if (Number(this.outdoorLineSet) + indoorLineSetTotal > 131) {
        alerts.push("The max height difference when the outdoor unit is below is 131'.");
      }
      if (Number(this.outdoorLineSet) + indoorLineSetTotal > 164) {
        alerts.push("The max height difference when the outdoor unit is above is 164'.");
      }
      return uniq(alerts);
    }
  },
  methods: {
    toggleFilter(key) {
      if (this.filters.active.includes(key)) {
        const index = this.filters.active.indexOf(key);
        this.filters.active.splice(index, 1);
      } else {
        this.filters.active.push(key);
      }
      this.findSystem();
    },
    createZones() {
      const diff = this.zoneCount - this.zones.length;
      const lineSet = this.indoorLineSet;

      if (diff > 0 && this.zones.length === 0) {
        this.zones = Array.from({ length: this.zoneCount }, () => {
          const zone = {name: "", lineSet: lineSet, btu: null, unitType: null};
          return zone;
        });
      }
      else if (diff > 0) {
        for (let i = 0; i < diff; i++) {
          const zone = {name: "", lineSet: lineSet, btu: null, unitType: null};
          this.zones.push(zone);
        }
      }
      else if (diff < 0) {
        this.zones = this.zones.slice(0, this.zoneCount);
      }

      if (this.zones.length === 1) {
        this.zones[0].lineSet = 0;
      } else if (!this.zones[0].lineSet) {
        this.zones[0].lineSet = lineSet;
      }

      if (this.zones.length == 1 && this.outdoorLineSet <= this.outdoorLineSetMax && this.outdoorLineSet !== "") {
        this.e1 = 2;
      }
      if (this.zones.length > 1 && this.outdoorLineSet !== "") {
        this.e1 = 2;
      }
    },
    setOutdoorFilters(systems, filterType) {
      const outdoorModels = systems.map((s) => {return s.outdoor});
      const filterList = multiProBrochure
        .filter((m) => {return outdoorModels.includes(m["Outdoor Model No."]);})
        .map((m) => {
          let text = '';
          if (filterType === 'energy') {
            text = `SEER / EER: ${m['SEER / EER']} - HSPF / COP: ${m['HSPF / COP']}`;
          }
          return {
            value: `${m["Outdoor Model No."]}`,
            text: text
          }
        }); 
      const filterListFinal = [];
      const filterListGrouped = groupBy(filterList, 'text');
      Object.keys(filterListGrouped).forEach((key) => {
        if (key != 'undefined') {
          filterListFinal.push(
            {
              value: filterListGrouped[key].map((fl) => {return fl.value}).join(','),
              text: key
            }
          )
        }
      });
      this.filters[filterType] = filterListFinal;

      const models = [];
      this.filters[filterType + 'Active'].forEach((fa) => {fa.split(',').forEach((fas) => {models.push(fas)})})
      return models;
    },
    async findSystem() {
      const emptyField = (this.zones.find((z) => {return !z.btu || z.lineSet === ""})) ?
        'Please fill out all empty fields.' : null;
      const lineSetTotalMax = (this.zones.length > 1 && (sumBy(this.zones, z => Number(z.lineSet)) + Number(this.outdoorLineSet)) > 492) ?
        `The total length of all line sets have exceeded the 492 ft. max. Please redesign.` : null;
      const lineSetLongestMax = (this.zones.length > 1 && (Number(maxBy(this.zones, z => Number(z.lineSet)).lineSet) + Number(this.outdoorLineSet)) > 213) ?
        `The longest branch pipe (Y-Branch or Header to Indoor Unit) has exceeded the 213 ft. max. Please redesign.` : null;

      const haultMsg = emptyField || lineSetTotalMax || lineSetLongestMax;
      if (haultMsg) {
        alert(haultMsg)
        return
      }

      const btus = (this.zones.map((z) => {return z.btu/1000})).sort((a, b) => {return a-b});

      const outdoors = [];
      Object.keys(multiProProducts.btus).forEach((key) => {
        const matches = multiProProducts.btus[key].filter((m) => {return m.length === parseInt(this.zoneCount)});
        if (matches.length) {
          const found = matches.find((m) => {
            for (let i = 0; i < m.length; i++) {
              if (m[i] != btus[i]) {
                return false
              }
            }
            return true;
          })
          if (found) {
            outdoors.push(key);
          }
        }
      })

      const indoors = [];
      multiProProducts.combinations.forEach((combination, i) => {
        indoors[i] = [];
        this.zones.forEach((z) => {
          const btu = (z.btu / 1000).toString().padStart(2, "0");
          const model = combination[z.unitType].find((m) => {return m.includes(btu)});
          if (model) {
            indoors[i].push({model, zone: z});
          }
        })
      })

      const systems = []
      const processedSystems = [];
      indoors.forEach((indoor) => {
        outdoors.forEach((outdoor) => {
          const indoorModels = indoor.map((ind) => {return ind.model;})
          const systemKey = `${outdoor}${indoorModels.join('')}`;
          const renderKey = `${outdoor}${indoorModels.join('')}-${new Date().getTime()}`;
          if (!processedSystems.includes(systemKey) && this.zones.length === indoorModels.length) {
            systems.push({'outdoor': outdoor, outdoorLineSet: this.outdoorLineSet, 'indoors': indoor, 'key': renderKey});
            processedSystems.push(systemKey);
          }
        });
      })

      const energyModels = this.setOutdoorFilters(systems, 'energy');
      const filteredSystems = systems.filter((sys) => {
        return (!energyModels.length || energyModels.includes(sys.outdoor))
      }).sort((a, b) => calculateCapacity(a) - calculateCapacity(b));

      this.systems = filteredSystems;
      this.e1 = 3;
    },
  },
};
</script>
