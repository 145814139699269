<template>
  <v-container grid-list-xl>
    <v-row>
      <v-col cols="12" sm="12" class="pb-0">
        <v-card>
          <v-form ref="form" lazy-validation>
            <v-card-title class="title primary white--text mb-4 text-capitalize">Give Feedback</v-card-title>
            <v-card-text>
              <p>
                Please help us improve System Builder by providing feedback.
              </p>
              <v-textarea
                outlined
                label=""
                v-model="feedback"
              ></v-textarea>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="submit()"
                :disabled="saving"
                :loading="saving"
                >
                Submit
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import fb from "@/main";
import { mapGetters } from "vuex";

export default {
  name: "Feedback",
  data() {
    return {
      feedback: "",
      saving: false,
      snack: false,
      snackColor: "",
      snackText: "",
    };
  },
  async created() {
  },
  computed: {
    ...mapGetters(["user"])
  },
  methods: {
    async submit() {
      try {
        if (this.feedback.trim().length) {
          this.saving = true;
          const obj = {
            createdBy: this.user,
            createDate: new Date(),
            feedback: this.feedback,
          }
          await fb.db.collection("feedback").add(obj);
          this.feedback = "";
          this.saving = false;
          this.snack = true;
          this.snackColor = "success";
          this.snackText = "Successfully submitted feedback.";
        }
      } catch (error) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error submitting feedback.";
      }
    },
  },
};
</script>
