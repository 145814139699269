<template>
  <v-container grid-list-xl>
    <!-- CUSTOMER INFO -->
    <v-row>
      <v-col cols="12" xs="12">
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search products"
              single-line
              hide-details
              outlined
              dense
              clearable
              @input="searchProducts()"
            ></v-text-field>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" class="pb-0">
        <div class="text-center">
          {{products.length}} results
        </div>
        <v-card
          v-for="product in products"
          :key="product.Model"
          :loading="loading"
          class="mx-auto my-12"
          max-width="800"
        >
          <template slot="progress">
            <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>

          <div
            class="d-flex px-4"
            style="
              align-items: center;
              justify-content: space-between;
              border-bottom: solid 1px #e8e8e8;
            "
          >
            <h2>{{ product["Type"] }}: {{ product["Indoor Model No."] || product["Outdoor Model No."] }}</h2>
            <v-img
              :src="`${product.logoImage}`"
              max-width="60"
            ></v-img>
          </div>

          <v-row v-if="product.indoorImage && product.outdoorImage">
            <v-col cols="6" sm="6" md="6" class="pa-5">
              <v-img :src="product.indoorImage" class="pa-4" contain></v-img>
            </v-col>
            <v-col cols="6" sm="6" md="6" class="pa-5">
              <v-img :src="product.outdoorImage" class="pa-4" contain></v-img>
            </v-col>
          </v-row>

          <v-row v-else>
            <v-col cols="6" sm="6" md="6" offset="3" class="pa-5">
              <v-img :src="product.indoorImage || product.outdoorImage" class="pa-4" contain></v-img>
            </v-col>
          </v-row>

          <v-card-text class="px-4 py-4">
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr v-for="(val, prop) in product.specs" :key="prop">
                    <th>
                      {{ prop }}
                    </th>
                    <td>
                      <strong v-if="val.toString().includes('http')"><a :href="val" target="_blank">View</a></strong>
                      <strong v-else>{{ val }}</strong>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import productsWebsite from "@/data/productsWebsite.json";
import singleZoneProducts from "@/data/singleZoneBrochure.json";
import multiZoneProducts from "@/data/multiZoneBrochure.json";
import multiProProducts from "@/data/multiProBrochure.json";
import centralAirProducts from "@/data/centralAirBrochure.json";
import {sortSpecs} from "@/helpers";
import { mapGetters } from "vuex";
import { debounce } from "lodash";

export default {
  name: "Home",
  data() {
    return {
      allProducts: [],
      products: [],
      search: "",
    };
  },
  async created() {
    this.getProducts();
  },
  computed: {
    ...mapGetters({
      user: "user",
      systemDocumentation: "systemDocumentation"
    }),
  },
  methods: {
    async getProducts() {
      singleZoneProducts.forEach((s) => { s.Type = 'Single Zone' });
      multiZoneProducts.forEach((s) => { s.Type = 'Multi Zone' });
      multiProProducts.forEach((s) => { s.Type = 'MultiPRO' });
      centralAirProducts.forEach((s) => { s.Type = 'Central Air' });
      const products = singleZoneProducts.concat(multiZoneProducts, multiProProducts, centralAirProducts);

      products.forEach((p) => {
        p.specs = sortSpecs(p, this.systemDocumentation);

        const indoor = productsWebsite.find((pw) => {
          return p["Indoor Model No."] && (pw.Model || "").includes(p["Indoor Model No."].slice(0, -2));
        });
        if (indoor) {
          p.indoorImage = indoor["Image URL"];
          p.logoImage = indoor["Image URL"].replace("feature-1", "logo");
        }
        const outdoor = productsWebsite.find((pw) => {
          return p["Outdoor Model No."] && (pw.Model || "").includes(p["Outdoor Model No."]);
        });
        if (outdoor) {
          p.outdoorImage = outdoor["Image URL"].replace("feature-1", "feature-2");
          p.logoImage = outdoor["Image URL"].replace("feature-1", "logo");
        }
      })
      this.allProducts = products;
      this.products = products;
      this.loading = false;
    },
    searchProducts: debounce(function() {
      if (!this.search) {
        this.products = this.allProducts;
      } else {
        this.products = this.allProducts.filter((p) => {
          return (p["Indoor Model No."] || '').toLowerCase().includes(this.search)
          || (p["Outdoor Model No."] || '').toLowerCase().includes(this.search)
          || (p["Name"] || '').toLowerCase().includes(this.search)
          || (p["Nominal Capacity (Btu)"] || '').toLowerCase().includes(this.search)
        })
      }
    }, 400),
  },
};
</script>
<style>
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: none !important;
}
</style>
