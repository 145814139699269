import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import fb from "@/main";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: null,
    equipment: [],
  },
  getters: {
    user: (state) => {
      return state.user;
    },
    systemDocumentation: (state) => {
      return state.systemDocumentation;
    },
  },
  mutations: {
    cacheUser(state, user) {
      state.user = user;
    },
    cacheSystemDocumentation(state, systemDocumentation) {
      state.systemDocumentation = systemDocumentation;
    },
  },
  actions: {
    signout: async function () {
      try {
        await fb.auth.signOut();
        router.push("/login");
      } catch (error) {
        alert(error);
      }
    },
    getUser: async function ({ commit }) {
      try {
        const { uid } = await fb.auth.currentUser;
        const userRes = await fb.db.collection("users").doc(uid).get();
        let user = userRes.data();
        commit("cacheUser", user);
      } catch (error) {
        alert(error);
      }
    },
    getSystemDocumentation: async function ({ commit,  }) {
      try {
        const getSystemDocumentation = fb.functions.httpsCallable('getSystemDocumentation');
        const { data: systemDocumentation } =  await getSystemDocumentation()
        commit("cacheSystemDocumentation", systemDocumentation);
      } catch (error) {
        console.log(error);
        alert(error);
      }
    },
    saveSystem: async function ({state}, system) {
      const payload = {
        ...system,
        createdBy: state.user,
        createDate: fb.timestamp
      };
      const systemId = system.systemId;

      if (systemId) {
        delete payload['systemId'];
        delete payload.system['key'];
        console.log(payload)
        await fb.db.collection("systems").doc(systemId).update(payload);
      } else {
        delete payload['systemId'];
        delete payload.system['key'];
        await fb.db.collection("systems").add(payload);
      }
      router.push(`/project/${system.projectId}`);
    },
    deleteSystem: async function ({state}, system) {
      console.log(state)
      if (confirm("Are you sure you want to delete this system?") == true) {
        await fb.db.doc(`systems/${system.systemId}`).delete();
        location.reload();
      }
    },
    openPdf: async function ({state}, data) {
      console.log(state)
      const projectRes = await fb.db.doc(`projects/${data.projectId}`).get();
      const project = projectRes.data();
      data.project = project;

      await fb.db.collection("pdf").doc(data.systemId).set(data);

      let url = `https://us-central1-gree-system-builder.cloudfunctions.net/generatePdf?systemId=${data.systemId}`;
      if (location.hostname === "localhost") {
        url = `http://localhost:5001/gree-system-builder/us-central1/generatePdf?systemId=${data.systemId}&webview=1`;
      }
      window.open(url, "_blank");
    }
  },
});

export default store;
