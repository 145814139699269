<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1">Zones</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 2" step="2">
        Zone Options
      </v-stepper-step>
      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 3" step="3">
        Selection
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content
        step="1"
        style="overflow-y: scroll; max-height: 700px"
      >
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="6" md="6" class="py-2" offset-sm="3">
              <v-alert
                border="top"
                colored-border
                color="info"
                type="info"
                elevation="1"
                class="text-center mb-7"
              >
                Need over {{zoneOptions.length}} zones and/or longer line set lengths? Please <a href="https://www.greecomfort.com/multipro-selection-software/" target="_blank">download</a> our MultiPRO Selection Software, an advanced layout and system selection tool for efficient and easy designing.
              </v-alert>
              <v-select
                :items="zoneOptions"
                name="zones"
                label="Number of Zones"
                outlined
                v-model="zoneCount"
              ></v-select>
              <div v-show="zoneCount > 0">
                <label class="d-block mb-2 grey--text text--darken-1 body-2">Outdoor to {{outdoorToLabel}}. (Max {{outdoorLineSetMax}} Ft.)</label>
                <v-text-field
                  label="Line Set Length"
                  value=""
                  outlined
                  type="number"
                  v-model="outdoorLineSet"
                ></v-text-field>
              </div>
              <div class="text-center">
                <v-btn color="primary" @click="createZones">
                  Continue
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-stepper-content>

      <v-stepper-content
        step="2"
        style="overflow-y: scroll; max-height: 700px"
      >
        <v-container fluid>
          <v-row>
            <v-col
              v-for="(zone, ix) in zones"
              :key="ix"
              cols="12"
              sm="6"
              md="6"
              class="py-2"
            >
              <v-card dense color="grey lighten-5 elevation-2">
                <v-card-text>
                  <v-combobox
                    name="zonename"
                    placeholder="Zone Name"
                    :items="zoneNames"
                    outlined
                    v-model="zone.name"
                    dense
                  >
                  </v-combobox>
                  <v-select
                    :items="unitOptions"
                    name="btu"
                    label="Unit Type"
                    outlined
                    v-model="zone.unitType"
                    v-show="zone.name"
                    dense
                  ></v-select>
                  <v-select
                    :items="btuOptions[zone.unitType]"
                    name="btu"
                    label="BTU"
                    outlined
                    v-model="zone.btu"
                    v-show="zone.name && zone.unitType"
                    dense
                  ></v-select>
                  <div v-show="zone.btu && zones.length > 1">
                    <label class="d-block mb-2">{{outdoorToLabel}} to {{zone.unitType}}. (Max {{indoorLineSetMax}} Ft.)</label>
                    <v-text-field
                      label="Line Set Length"
                      value=""
                      outlined
                      type="number"
                      v-model="zone.lineSet"
                    ></v-text-field>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <v-btn text class="mr-2" @click="e1 = 1">Back</v-btn>
              <v-btn color="primary" @click="findSystem">Continue</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-stepper-content>
      <v-stepper-content
        step="3"
        style="overflow-y: scroll; max-height: 700px; background: #f4f4f4"
      >
        <div class="text-center mt-5">
          <v-btn color="primary" @click="e1 = 2">back</v-btn>
        </div>
        <v-row>
          <v-col cols="12" sm="6" md="6" class="py-2 mt-5" offset-sm="3">
            <v-select
              v-if="filters.energy.length > 1"
              v-model="filters.energyActive"
              :items="filters.energy"
              item-text="text"
              item-value="value"
              attach
              chips
              label="Filter by Energy Efficiency"
              multiple
              @change="findSystem"
            ></v-select>
            <div class="text-center mt-3">Systems Found: {{systems.length}}</div>
            <v-alert
              class="mt-5"
              border="top"
              colored-border
              color="warning"
              type="error"
              elevation="1"
              v-show="!systems.length"
            >
              {{noResultsMessage}}
            </v-alert>
          </v-col>
        </v-row>
        <div v-for="system in systems" :key="system.key">
          <MultiProStandardProductCard :system="system"></MultiProStandardProductCard>
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import fb from "@/main";
import { groupBy } from "lodash";
import multiProProducts from "@/data/multiProBuilder.json";
import MultiProStandardProductCard from "@/components/MultiProStandardProductCard";
import multiProBrochure from "@/data/multiProBrochure.json";

export default {
  name: "MultiProStandard",
  components: { MultiProStandardProductCard },
  data() {
    return {
      e1: 1,
      type: null,
      unitOptions: ['Wall Mount', 'Compact Cassette', 'Standard Cassette', 'One Way Cassette', 'Two Way Cassette', 'Compact Console', 'Floor Ceiling', 'Slim Ducted', 'Multi-Position Air Handler'],
      btuOptions: {
        'Wall Mount': [7000, 9000, 12000, 18000, 24000, 30000, 36000],
        'Compact Cassette': [7000, 9000, 12000, 15000, 18000],
        'Standard Cassette': [12000, 18000, 24000, 36000, 48000],
        'One Way Cassette': [7000, 9000, 12000],
        'Two Way Cassette': [9000, 12000, 15000, 18000, 24000],
        'Compact Console': [7000, 9000, 12000, 18000],
        'Floor Ceiling': [9000, 12000, 18000, 24000, 36000, 48000],
        'Slim Ducted': [9000, 12000, 18000, 24000, 30000, 36000, 48000, 54000],
        'Multi-Position Air Handler': [12000, 18000, 24000, 30000, 36000, 48000, 54000, 60000]
      },
      zoneNames: ['Basement', 'Bedroom', 'Dining Room', 'Kitchen', 'Living Room', 'Office', 'Room', 'Zone'],
      zoneOptions: [1, 2, 3, 4, 5],
      zoneCount: null,
      zones: [],
      systems: [],
      outdoorLineSet: 98,
      outdoorLineSetMax: 98,
      indoorLineSet: 50,
      indoorLineSetMax: 50,
      noResultsMessage: "Sorry, we don't have a system that supports the combination of your selected indoor capacities. Please revise and try again.",
      filters: {
        energy: [],
        energyActive: []
      },
    };
  },
  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    const systemId = urlParams.get('systemId');
    if (systemId) {
      const doc = await fb.db
        .collection("systems")
        .doc(systemId)
        .get();
      const system = doc.data();
      const zones = system.system.indoors.map((indoor) => {return indoor.zone;});
      this.zones = zones;
      this.zoneCount = zones.length;
      this.outdoorLineSet = system.system.outdoorLineSet;
      this.e1 = 2;
    }
  },
  computed: {
    outdoorToLabel() {
      if (!this.zoneCount || this.zoneCount == 1) {
        return "Indoor";
      }
      if (this.zoneCount == 2) {
        return "Y-Branch"
      }
      return "Header"
    }
  },
  methods: {
    toggleFilter(key) {
      if (this.filters.active.includes(key)) {
        const index = this.filters.active.indexOf(key);
        this.filters.active.splice(index, 1);
      } else {
        this.filters.active.push(key);
      }
      this.findSystem();
    },
    createZones() {
      const diff = this.zoneCount - this.zones.length;
      const lineSet = this.indoorLineSet;

      if (diff > 0 && this.zones.length === 0) {
        this.zones = Array.from({ length: this.zoneCount }, () => {
          const zone = {name: "", lineSet: lineSet, btu: null, unitType: null};
          return zone;
        });
      }
      else if (diff > 0) {
        for (let i = 0; i < diff; i++) {
          const zone = {name: "", lineSet: lineSet, btu: null, unitType: null};
          this.zones.push(zone);
        }
      }
      else if (diff < 0) {
        this.zones = this.zones.slice(0, this.zoneCount);
      }

      if (this.zones.length === 1) {
        this.zones[0].lineSet = 0;
      } else if (!this.zones[0].lineSet) {
        this.zones[0].lineSet = lineSet;
      }

      if (this.zones.length && this.outdoorLineSet <= this.outdoorLineSetMax && this.outdoorLineSet !== "") {
        this.e1 = 2;
      }
    },
    setOutdoorFilters(systems, filterType) {
      const outdoorModels = systems.map((s) => {return s.outdoor});
      const filterList = multiProBrochure
        .filter((m) => {return outdoorModels.includes(m["Outdoor Model No."]);})
        .map((m) => {
          let text = '';
          if (filterType === 'energy') {
            text = `SEER / EER: ${m['SEER / EER']} - HSPF / COP: ${m['HSPF / COP']}`;
          }
          return {
            value: `${m["Outdoor Model No."]}`,
            text: text
          }
        }); 
      const filterListFinal = [];
      const filterListGrouped = groupBy(filterList, 'text');
      Object.keys(filterListGrouped).forEach((key) => {
        if (key != 'undefined') {
          filterListFinal.push(
            {
              value: filterListGrouped[key].map((fl) => {return fl.value}).join(','),
              text: key
            }
          )
        }
      });
      this.filters[filterType] = filterListFinal;

      const models = [];
      this.filters[filterType + 'Active'].forEach((fa) => {fa.split(',').forEach((fas) => {models.push(fas)})})
      return models;
    },
    async findSystem() {
      const emptyField = (this.zones.find((z) => {return !z.btu || z.lineSet === ""})) ?
        'Please fill out all empty fields.' : null;
      const lineSetIndoorMax = this.zones.find((z) => {return !(z.lineSet <= this.indoorLineSetMax)}) ?
        `Please correct the line set that is exceeding the y-branch to indoor ${this.indoorLineSetMax} ft. max.` : null;

      const haultMsg = emptyField || lineSetIndoorMax;
      if (haultMsg) {
        alert(haultMsg)
        return
      }

      const btus = (this.zones.map((z) => {return z.btu/1000})).sort((a, b) => {return a-b});

      const outdoors = [];
      Object.keys(multiProProducts.btus).forEach((key) => {
        const matches = multiProProducts.btus[key].filter((m) => {return m.length === parseInt(this.zoneCount)});
        if (matches.length) {
          const found = matches.find((m) => {
            for (let i = 0; i < m.length; i++) {
              if (m[i] != btus[i]) {
                return false
              }
            }
            return true;
          })
          if (found) {
            outdoors.push(key);
          }
        }
      })

      const indoors = [];
      multiProProducts.combinations.forEach((combination, i) => {
        indoors[i] = [];
        this.zones.forEach((z) => {
          const btu = (z.btu / 1000).toString().padStart(2, "0");
          const model = combination[z.unitType].find((m) => {return m.includes(btu)});
          if (model) {
            indoors[i].push({model, zone: z});
          }
        })
      })

      const systems = []
      const processedSystems = [];
      indoors.forEach((indoor) => {
        outdoors.forEach((outdoor) => {
          const indoorModels = indoor.map((ind) => {return ind.model;})
          const systemKey = `${outdoor}${indoorModels.join('')}`;
          const renderKey = `${outdoor}${indoorModels.join('')}-${new Date().getTime()}`;
          if (!processedSystems.includes(systemKey) && this.zones.length === indoorModels.length) {
            systems.push({'outdoor': outdoor, outdoorLineSet: this.outdoorLineSet, 'indoors': indoor, 'key': renderKey});
            processedSystems.push(systemKey);
          }
        });
      })

      const energyModels = this.setOutdoorFilters(systems, 'energy');
      const filteredSystems = systems.filter((sys) => {
        return (!energyModels.length || energyModels.includes(sys.outdoor))
      });

      this.systems = filteredSystems;
      this.e1 = 3;
    },
  },
};
</script>
