<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1">Options</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 2" step="2">
        Selection
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content
        step="1"
        style="overflow-y: scroll; max-height: 700px"
      >
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="6" md="6" class="py-2" offset-sm="3">
              <v-select
                :items="btuOptions"
                name="btu"
                label="BTU"
                outlined
                v-model="zone.btu"
              ></v-select>
              <label
                v-show="zone.btu"
                class="mb-2 grey--text text--darken-1 body-2"
              >Along with the Central Indoor Air Handler, would you like to add one or more indoor units?</label>
              <v-select
                v-show="zone.btu"
                :items="indoorRequiredOptions"
                name="indoor"
                label=""
                outlined
                v-model="zone.indoorRequired"
              ></v-select>
              <div class="text-center">
                <v-btn color="primary" @click="findSystem()" v-show="!zone.indoorRequired || zone.indoorRequired == 'No'">
                  Continue
                </v-btn>
                <v-alert
                  border="top"
                  colored-border
                  color="info"
                  type="info"
                  elevation="1"
                  v-show="zone.indoorRequired == 'Yes'"
                >
                  To add one or more units to your Central Indoor Air Handler system, you will require a MultiPRO System.
                  <br/>Please edit your system type selection.
                </v-alert>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-stepper-content>

      <v-stepper-content
        step="2"
        style="overflow-y: scroll; max-height: 700px; background: #f4f4f4"
      >
        <div class="text-center mt-5">
          <v-btn color="primary" @click="e1 = 1">back</v-btn>
        </div>
        <div v-for="system in systems" :key="system.key">
          <CentralAirProductCard :system="system"></CentralAirProductCard>
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import fb from "@/main";
import CentralAirProductCard from "@/components/CentralAirProductCard";
import centralAirProducts from "@/data/centralAirBuilder.json";

export default {
  name: "CentralAir",
  components: { CentralAirProductCard },
  data() {
    return {
      e1: 1,
      btuOptions: ['24000', '36000', '48000', '60000'],
      indoorRequiredOptions: ['No', 'Yes'],
      zone: {
        btu: null
      },
      systems: []
    };
  },
  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    const systemId = urlParams.get('systemId');
    if (systemId) {
      const doc = await fb.db
        .collection("systems")
        .doc(systemId)
        .get();
      const system = doc.data();
      if (system.type == 'Central Air') {
        this.zone = system.system.zone;
      }
    }
  },
  computed: {
  },
  methods: {
    findSystem() {
      if (!this.zone.btu || !this.zone.indoorRequired) {
        alert('Please fill out all empty fields.')
        return;
      }

      const matches = [];
      const obj = centralAirProducts[this.zone.btu];
      if (obj) {
        matches.push(obj);
      }
      this.systems = matches.map((model) => {return {model: model, zone: this.zone, key: `${model.indoor}${model.outdoor}`}});
      this.e1 = 2;
    },
  },
};
</script>
