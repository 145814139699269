<template>
  <v-card class="mx-auto my-12" max-width="800" v-if="product">
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <div
      class="d-flex px-4"
      style="
        align-items: center;
        justify-content: space-between;
        border-bottom: solid 1px #e8e8e8;
      "
    >
      <h3><span v-if="projectView">Central Air: </span>{{ name }}</h3>
      <v-img :src="this.logo" max-width="60"></v-img>
    </div>
    <v-card-text class="pa-4">
      <v-row>
        <v-col cols="6" sm="6" md="6" class="pa-5">
          <v-img :src="this.image2" class="pa-4" contain></v-img>
        </v-col>
        <v-col cols="6" sm="6" md="6" class="pa-5">
          <v-img :src="this.image1" class="pa-4" contain></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pa-5">
          <tbody style="width: 100%; display: table">
            <tr v-for="(val, prop) in product" :key="prop">
              <td>
                {{ prop }}
              </td>
              <td class="text-right">
                <strong v-if="val.toString().includes('http')"><a :href="val" target="_blank">View</a></strong>
                <strong v-else>{{ val }}</strong>
              </td>
            </tr>
          </tbody>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-btn v-if="projectView" color="primary" @click="generatePdf" :loading="loadingPdf" :disabled="loadingPdf">Generate PDF</v-btn>
      <v-spacer></v-spacer>
      <v-btn v-if="!projectView" color="primary" @click="selectProduct"
        >Select</v-btn
      >
      <v-btn v-else color="primary" @click="edit">Edit</v-btn>
      <v-btn v-if="system.id" color="error" outlined @click="remove">Delete</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import products from "@/data/productsWebsite.json";
import productsBrochure from "@/data/centralAirBrochure.json";
import { mapGetters } from "vuex";
import { sortSpecs } from "@/helpers";

export default {
  name: "CentralAirProductCard",
  props: ["system", "projectView"],
  data() {
    return {
      type: "Central Air",
      loadingPdf: false,
      product: null,
      image1: "",
      image2: "",
      logo: "",
      name: "",
    };
  },
  created() {
    const model = this.system.model;
    const indoor = products.find((p) => {
      return (p.Model || "").includes(model.outdoor);
    });
    const indoorBrochure = productsBrochure.find((p) => {
      return (
        p["Indoor Model No."] === model.indoor &&
        p["Outdoor Model No."] === model.outdoor
      );
    });
    const outdoor = products.find((p) => {
      return (p.Model || "").includes(indoorBrochure["Outdoor Model No."]);
    });
    this.product = sortSpecs(indoorBrochure, this.systemDocumentation);
    this.name = indoor["Product Name"];
    this.image1 = indoor["Image URL"];
    this.image2 = indoor["Image URL"].replace("feature-1", "feature-2");
    this.logo = outdoor["Image URL"].replace("feature-1", "logo");
  },
  computed: {
    ...mapGetters({
      systemDocumentation: "systemDocumentation"
    }),
  },
  methods: {
    async selectProduct() {
      const urlParams = new URLSearchParams(window.location.search);
      const systemId = urlParams.get('systemId');

      this.$store.dispatch("saveSystem", {
        system: { ...this.system },
        type: "Central Air",
        projectId: this.$route.params.projectId,
        systemId: systemId
      });
    },
    edit() {
      this.$router.push(
        `/project/${this.$route.params.projectId}/builder?systemId=${this.system.id}`
      );
    },
    remove() {
      this.$store.dispatch("deleteSystem", {
        systemId: this.system.id
      });
    },
    async generatePdf() {
      const projectId = this.$route.params.projectId;
      const systemId = this.system.id;

      const data = {
        projectId: projectId,
        systemId: systemId,
        type: this.type,
        product: this.product,
        name: this.name,
        image1: this.image1,
        image2: this.image2,
        logo: this.logo
      }

      this.loadingPdf = true;
      await this.$store.dispatch("openPdf", data);
      this.loadingPdf = false;
    },
  },
};
</script>
