import fb from "@/main";
import { sum } from "lodash";

const setSubmittal = (specs, systemDocumentation) => {
  const outdoorModel = specs['Outdoor Model No.'];
  const indoorModel = specs['Indoor Model No.'];

  const submittalOutdoor = systemDocumentation.find((add) => outdoorModel && add.model && outdoorModel.toLowerCase() === add.model.toLowerCase() && add.category === 'Submittals');
  const submittalIndoor = systemDocumentation.find((add) => indoorModel && add.model && indoorModel.toLowerCase() === add.model.toLowerCase() && add.category === 'Submittals');
  const submittalOutdoorIndoor = systemDocumentation.find((add) => indoorModel && outdoorModel && add.model && `${indoorModel},${outdoorModel}`.toLowerCase() === add.model.toLowerCase() && add.category === 'Submittals');
  const submittalIndoorOutdoor = systemDocumentation.find((add) => outdoorModel && indoorModel && add.model && `${outdoorModel},${indoorModel}`.toLowerCase() === add.model.toLowerCase() && add.category === 'Submittals');

  const submittal = submittalOutdoor || submittalIndoor || submittalOutdoorIndoor || submittalIndoorOutdoor;
  if (submittal) {
    specs.Submittal = `https://www.greecomfort.com/${submittal.url}`;
  }
}

const sortSpecs = (specs, systemDocumentation) => {
  const orderedSpecs = [
    'Name',
    'Indoor Model No.',
    'Indoor Model Type',
    'Outdoor Model No.',
    'Nominal Capacity (Btu)',
    'SEER / EER',
    'SEER / EER (Non-Ducted)',
    'SEER / EER (Ducted)',
    'SEER2 / EER2 (Non-Ducted)',
    'SEER2 / EER2 (Ducted)',
    'SEER2 / EER2',
    'HSPF2 / COP',
    'HSPF / COP',
    'HSPF2 / COP@5°F (Non-Ducted)',
    'HSPF2 / COP@5°F (Ducted)',
    'Initial Unit Charge',
    'Min/Max Connections',
    'ODU Pipe Connections ',
    'Max Lift (ft.)',
    'Min Line Length (ft.)',
    'Max Line Length (ft.)',
    'Max Line Length',
    'Max Pipe Length',
    'Max Length to Indoor Unit',
    'Max Lift/Drop OD to ID units',
    'Max Lift/ Drop OD to ID units',
    'Line Set (Port) Size',
    'Liquid Pipe',
    'Gas Pipe',
    'ODU Factory Charge',
    'Charge Value',
    'MCA / MOCP (INDOOR-NO HEATER)',
    'MCA / MOCP (OUTDOOR)',
    'MCA / MOCP',
    'MCA / MCOP',
    'MCA',
    'MOCP',
    'Min AWG',
    'Recommended Breaker Size',
    'Rated Cooling Capacities',
    'Rated Heating Capacities',
    'Cooling Range (°F)',
    'Heating Range (°F)',
    'Energy Star',
    'WIFI',
    'AHRI Numbers',
    'AHRI Cert. # (Non-Ducted)',
    'AHRI Cert. # (Ducted)',
    'Submittal'
  ];

  setSubmittal(specs, systemDocumentation)

  const sortedSpecs = {};
  orderedSpecs.forEach((key) => {
    if (specs[key]) {
      sortedSpecs[key] = specs[key];
    }
  })

  return sortedSpecs;
}

const liquidPipeChargeValueOverride = (type, specs, lineSet, outdoorModel) => {
  const modifiedSpecs = JSON.parse(JSON.stringify(specs));
  if (type === 'MultiPRO Standard') {
    if (lineSet >= 33 && modifiedSpecs["Liquid Pipe"] === "1/4") {
      modifiedSpecs["Liquid Pipe"] = "3/8";
      modifiedSpecs["Charge Value"] = "0.58";
    }
  }
  if (type === 'MultiPRO Ultra') {
    if (["GMV-36WL/B-T(U)", "GMV-36WL/B-T(U)"].includes(outdoorModel) && lineSet >= 33 && modifiedSpecs["Liquid Pipe"] === "1/4") {
      modifiedSpecs["Liquid Pipe"] = "3/8";
      modifiedSpecs["Charge Value"] = "0.58";
    }
    if (["GMV-V36WL/C-T(U)", "GMV-V48WL/C-T(U)", "GMV-V60WL/C-T(U)"].includes(outdoorModel) && lineSet >= 50 && ["5K", "6K", "7K", "9K", "12K", "15K"].includes(modifiedSpecs["Nominal Capacity (Btu)"])) {
      modifiedSpecs["Liquid Pipe"] = "3/8";
      modifiedSpecs["Gas Pipe"] = "1/2";
      modifiedSpecs["Charge Value"] = "0.58";
    }
  }
  return modifiedSpecs;
}

const calculateCharge = async (type, indoors, outdoor) => {
  const getTotalCharge = fb.functions.httpsCallable('getTotalCharge');
  const { data: totalCharge } = await getTotalCharge({type, indoors, outdoor})
  return totalCharge;
}

const calculateCapacity = (system) => {
  const extractFirstDigits = (str) => {
    const regex = /\d+/;
    const match = str.match(regex);
    return match ? match[0] : null;
  }
  const indoorModels = system.indoors.map((indoor) => {return indoor.model});
  const indoorBtus = sum(indoorModels.map((model) => {return extractFirstDigits(model) * 1}));
  const outdoorBtus = extractFirstDigits(system.outdoor) * 1;
  return Math.abs(indoorBtus/outdoorBtus - 1);
}

export {
  sortSpecs,
  calculateCharge,
  calculateCapacity,
  liquidPipeChargeValueOverride
}