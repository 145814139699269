<template>
  <v-container grid-list-lg>
    <!-- CUSTOMER INFO -->
    <v-row>
      <v-col cols="12" sm="12" class="pb-0">
        <div class="text-center" v-if="loading">
          <v-progress-circular indeterminate large></v-progress-circular>
        </div>
        <v-card v-if="!loading">
          <v-card-title
            class="py-4 pl-1 ma-0 primary white--text font-weight-black headline"
            style="overflow: hidden"
          >
            <span class="ml-4">Project Details</span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="pt-4 px-1">
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="6" md="6" class="py-0">
                  <v-text-field
                    name="name"
                    label="Project Name"
                    id="id"
                    v-model="project.projectName"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-0">
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="project.date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formattedDate"
                        label="Date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="project.date" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(project.date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-0">
                  <v-text-field
                    name="name"
                    label="Project Notes"
                    v-model="project.notes"
                    id="id"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-0">
                  <v-dialog
                    ref="estDialog"
                    v-model="estModal"
                    :return-value.sync="project.estDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formattedEstDate"
                        label="Est. Delivery Date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="project.estDate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="estModal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.estDialog.save(project.estDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-0 pt-3">
                  <h3>Customer Information</h3>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-text-field
                    name="firstName"
                    label="First Name"
                    v-model="project.firstName"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-text-field
                    name="lastName"
                    label="Last Name"
                    v-model="project.lastName"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-text-field
                    v-model="project.phone"
                    v-mask="'(###) ###-####'"
                    label="Phone Number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-text-field
                    v-model="project.email"
                    label="Customer Email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-0">
                  <v-text-field
                    name="street1"
                    label="Street 1"
                    v-model="project.address.street1"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-0">
                  <v-text-field
                    name="street2"
                    label="Street 2"
                    v-model="project.address.street2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-0">
                  <v-text-field
                    name="city"
                    label="City"
                    v-model="project.address.city"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="py-0">
                  <v-select
                    :items="states"
                    label="State"
                    name="state"
                    v-model="project.address.state"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="py-0">
                  <v-text-field
                    name="zip"
                    label="Zip"
                    v-model="project.address.zip"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="mr-2" color="primary" @click="save"
              ><v-icon left class="mr-1">save</v-icon> save</v-btn
            >
          </v-card-actions>
        </v-card>
        <div
          v-if="$route.name === 'edit-project' && !loading"
          class="pt-4"
        >
          <div class="text-center">
            <v-btn
              large
              dark
              rounded
              class="mb-0"
              color="green"
              :to="`/project/${this.$route.params.projectId}/builder`"
              >build a new system</v-btn
            >
          </div>
          <div v-for="system in systems" :key="system.id" class="mx-auto my-4">
            <SingleZoneProductCard
              v-if="system.type == 'Single Zone'"
              :system="system.system"
              :projectView="true"
            ></SingleZoneProductCard>
            <MultiZoneProductCard
              v-if="system.type == 'Multi Zone'"
              :system="system.system"
              :projectView="true"
            ></MultiZoneProductCard>
            <MultiProStandardProductCard
              v-if="system.type == 'MultiPRO Standard'"
              :system="system.system"
              :projectView="true"
            ></MultiProStandardProductCard>
            <MultiProUltraProductCard
              v-if="system.type == 'MultiPRO Ultra'"
              :system="system.system"
              :projectView="true"
            ></MultiProUltraProductCard>
            <CentralAirProductCard
              v-if="system.type == 'Central Air'"
              :system="system.system"
              :projectView="true"
            ></CentralAirProductCard>
          </div>
        </div>

        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
          {{ snackText }}
          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mask } from "vue-the-mask";
import moment from "moment";
import fb from "@/main";
import { mapGetters } from "vuex";
import SingleZoneProductCard from "@/components/SingleZoneProductCard.vue";
import MultiZoneProductCard from "@/components/MultiZoneProductCard";
import MultiProStandardProductCard from "@/components/MultiProStandardProductCard.vue";
import MultiProUltraProductCard from "@/components/MultiProUltraProductCard.vue";
import CentralAirProductCard from "@/components/CentralAirProductCard.vue";
export default {
  components: {
    SingleZoneProductCard,
    MultiZoneProductCard,
    MultiProStandardProductCard,
    MultiProUltraProductCard,
    CentralAirProductCard,
  },
  name: "new-project",
  directives: {
    mask,
  },
  data() {
    return {
      editing: false,
      systems: [],
      dialogProjectSubmitted: false,
      loading: true,
      dialogEquipment: false,
      modal: false,
      estModal: false,
      snack: false,
      snackColor: "",
      snackText: "",
      project: null,
      states: [
        "Alaska",
        "Alabama",
        "Arkansas",
        "American Samoa",
        "Arizona",
        "California",
        "Colorado",
        "Connecticut",
        "District of Columbia",
        "Delaware",
        "Florida",
        "Georgia",
        "Guam",
        "Hawaii",
        "Iowa",
        "Idaho",
        "Illinois",
        "Indiana",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Massachusetts",
        "Maryland",
        "Maine",
        "Michigan",
        "Minnesota",
        "Missouri",
        "Mississippi",
        "Montana",
        "North Carolina",
        "North Dakota",
        "Nebraska",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "Nevada",
        "New York",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Virginia",
        "Virgin Islands",
        "Vermont",
        "Washington",
        "Wisconsin",
        "West Virginia",
        "Wyoming",
      ],
    };
  },
  async created() {
    this.project = {
      date: moment().format("YYYY-MM-DD"),
      estDate: null,
      projectName: "",
      firstName: "",
      lastName: "",
      phone: "",
      address: {
        street1: "",
        street2: "",
        city: "",
        state: "",
        zip: "",
      },
      notes: "",
    };
    if (this.$route.name === "edit-project") {
      var docRef = fb.db
        .collection("projects")
        .doc(this.$route.params.projectId);
      const doc = await docRef.get();
      if (doc.exists) {
        const project = doc.data();
        this.project = {
          ...project,
        };
        await this.getSystems();
        this.loading = false;
      } else {
        this.$router.push("/");
      }
    } else {
      this.loading = false;
    }
  },
  computed: {
    formattedDate() {
      return this.project.date
        ? moment(this.project.date).format("MMMM D YYYY")
        : "";
    },
    formattedEstDate() {
      return this.project.estDate
        ? moment(this.project.estDate).format("MMMM D YYYY")
        : "";
    },
    ...mapGetters(["user"]),
  },
  methods: {
    async getSystems() {
      var res = await fb.db
        .collection("systems")
        .where("createdBy.uid", "==", this.user.uid)
        .where("projectId", "==", this.$route.params.projectId)
        .get();
      const systems = [];
      res.forEach((s) => {
        const sys = { ...s.data() };
        sys.system.id = s.id;
        systems.push(sys);
      });
      this.systems = systems;
    },
    async save() {
      const editingProject = this.$route.name === "edit-project";
      const project = {
        ...this.project,
      };
      if (editingProject) {
        project.lastUpdated = fb.timestamp;
      } else {
        project.createDate = fb.timestamp;
        project.createdBy = this.user;
      }
      try {
        if (editingProject) {
          await fb.db
            .collection("projects")
            .doc(this.$route.params.projectId)
            .update(project);
        } else {
          const res = await fb.db.collection("projects").add(project);
          this.$router.push(`/project/${res.id}`);
        }
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Successfully saved project.";
      } catch (error) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error saving project.";
        alert(error.message)
      }
    },
  },
};
</script>
<style scoped>
.markup >>> .v-input__control {
  border: solid 1px #f2f2f2;
  border-radius: 3px;
  margin-bottom: 5px;
  padding-left: 2px;
  text-align: center;
  font-size: 0.875rem;
}
</style>
