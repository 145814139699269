var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('v-app',[(_vm.privateRoute())?_c('v-app-bar',{attrs:{"clipped-left":_vm.$vuetify.breakpoint.lgAndUp,"app":"","color":"primary","dark":""}},[_c('img',{staticClass:"d-inline-block ml-3",staticStyle:{"cursor":"pointer"},attrs:{"src":"https://www.greecomfort.com/assets/logo/logo-inverse.png","width":"100"},on:{"click":_vm.goHome}}),_c('span',{staticClass:"mt-3 ml-1",staticStyle:{"font-size":"12px","opacity":"0.7"}},[_vm._v("v0.12")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"text":"","outlined":"","to":"/"}},[_vm._v("Home")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-avatar',{staticClass:"secondary lighten-1",attrs:{"size":36}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("menu")])],1)],1)]}}],null,false,1803638745)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{attrs:{"color":"primary","text":"","to":"/products"}},[_vm._v("Products")])],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{attrs:{"color":"primary","text":"","to":"/feedback"}},[_vm._v("Give Feedback")])],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.signout}},[_vm._v("Signout")])],1)],1)],1)],1)],1):_vm._e(),_c('v-main',{staticClass:"content",style:({
        backgroundImage: 'url(' + _vm.image + ')',
        backgroundSize: 'Cover',
        backgroundPosition: 'top',
      })},[_c('div',{staticClass:"pa-2"},[_c('router-view')],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }