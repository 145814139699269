<template>
  <v-container grid-list-xl>
    <!-- CUSTOMER INFO -->
    <v-row>
      <v-col cols="12" sm="12" class="pb-4">
        <v-card>
          <v-card-title
            class="ma-0 primary white--text font-weight-black headline"
            style="overflow: hidden"
          >
            <span class="ml-1">Projects</span>
            <v-btn class="ml-4" color="white" small dark outlined to="/project"
              ><v-icon small class="mr-1">description</v-icon> create new</v-btn
            >
          </v-card-title>
          <v-card-text class="pt-4 px-1">
            <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
            <v-container fluid>
              <v-row>
                <v-col cols="12" class="py-0" v-if="!loading">
                  <v-data-table
                    :headers="headers"
                    :items="projects"
                    :search="search"
                  >
                    <template v-slot:top>
                      <v-text-field
                        v-model="search"
                        outlined
                        label="Filter by project name or customer"
                        dense
                      ></v-text-field>
                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                          <v-card-title>Are you sure?</v-card-title>
                          <v-card-text>
                            Click on the delete button below to delete the
                            project permanently.
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="dialogDelete = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="primary"
                              @click="confirmDelete"
                              :loading="dialogDeleteLoading"
                              :disabled="dialogDeleteLoading"
                              >Delete</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </template>
                    <template v-slot:item.date="{ item }">
                      {{ item.date | moment("MMM D YYYY") }}
                    </template>
                    <template v-slot:item.edit="{ item }">
                      <v-btn
                        color="primary"
                        text
                        outlined
                        small
                        class="mr-2"
                        :to="`/project/${item.id}`"
                        >EDIT</v-btn
                      >
                      <v-btn
                        color="error"
                        text
                        outlined
                        small
                        @click="deleteProject(item)"
                        ><v-icon small>delete</v-icon></v-btn
                      >
                    </template>
                    <template slot="no-data">
                      <span class="grey--text text--darken-3">
                        You have not created any projects yet. Click the
                        <strong>"Create New"</strong>
                        button above.
                      </span>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import moment from "moment";
import fb from "@/main";
import { mapGetters } from "vuex";
import { sortBy } from "lodash";
export default {
  name: "Home",
  data() {
    return {
      dialogDeleteLoading: false,
      dialogDelete: false,
      search: "",
      loading: true,
      dataExists: false,
      snack: false,
      snackColor: "",
      snackText: "",
      additionalOptions: ["Duplicate"],
      estDate: null,
      headers: [
        {
          text: "PROJECT NAME",
          value: "projectName",
        },
        { text: "CUSTOMER", value: "name" },
        { text: "ADDRESS", value: "address.street1" },
        { text: "DATE", value: "date" },
        { text: "", value: "edit", align: "end", width: "260" },
      ],
      projects: [],
    };
  },
  async created() {
    this.getProjects();
  },
  computed: {
    formattedDate() {
      return this.project.date
        ? moment(this.project.date).format("MMMM D YYYY")
        : "";
    },
    formattedEstDate() {
      return this.project.estDate
        ? moment(this.project.estDate).format("MMMM D YYYY")
        : "";
    },
    ...mapGetters(["user"]),
  },
  methods: {
    async getProjects() {
      const projectsRes = await fb.db
        .collection("projects")
        .where("createdBy.uid", "==", this.user.uid)
        .get();
      const projects = [];
      projectsRes.forEach((q) => {
        const pData = q.data();
        projects.push({
          ...pData,
          name: `${pData.firstName} ${pData.lastName}`,
          id: q.id,
        });
      });
      this.projects = sortBy(projects, 'date').reverse();
      this.loading = false;
    },
    deleteProject(project) {
      this.currentProject = {};
      this.currentProject = { ...project };
      this.dialogDelete = true;
    },
    async confirmDelete() {
      try {
        this.dialogDeleteLoading = true;
        const projectId = this.currentProject.id;
        const systemsRes = await fb.db
        .collection("systems")
        .where("createdBy.uid", "==", this.user.uid)
        .where("projectId", "==", projectId)
        .get();
        const systemDocIds = [];
        systemsRes.forEach((q) => {
          systemDocIds.push(q.id);
        });
        for(const systemDocId of systemDocIds) {
          await fb.db.doc(`systems/${systemDocId}`).delete();
        }
        await fb.db.doc(`projects/${projectId}`).delete();
        await this.getProjects();
        this.dialogDelete = false;
        this.currentProject = {};
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Successfully deleted project.";
      } catch (error) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error deleting project.";
      }
      this.dialogDeleteLoading = false;
    },
  },
};
</script>
