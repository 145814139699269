<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1">Zones</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 2" step="2">
        Zone Options
      </v-stepper-step>
      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 3" step="3">
        Selection
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content
        step="1"
        style="overflow-y: scroll; max-height: 700px"
      >
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="6" md="6" class="py-2" offset-sm="3">
              <v-select
                :items="zoneOptions"
                name="zones"
                label="Number of Zones"
                outlined
                v-model="zoneCount"
              ></v-select>
              <div class="text-center">
                <v-btn color="primary" @click="createZones">
                  Continue
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-stepper-content>

      <v-stepper-content
        step="2"
        style="overflow-y: scroll; max-height: 700px"
      >
        <v-container fluid>
          <v-row>
            <v-col
              v-for="(zone, ix) in zones"
              :key="ix"
              cols="12"
              sm="6"
              md="6"
              class="py-2"
            >
              <v-card dense color="grey lighten-5 elevation-2">
                <v-card-text>
                  <v-combobox
                    name="zonename"
                    placeholder="Zone Name"
                    :items="zoneNames"
                    outlined
                    v-model="zone.name"
                    dense
                  >
                  </v-combobox>
                  <v-select
                    :items="unitOptions"
                    name="btu"
                    label="Unit Type"
                    outlined
                    v-model="zone.unitType"
                    v-show="zone.name"
                    dense
                  ></v-select>
                  <v-select
                    :items="btuOptions[zone.unitType]"
                    name="btu"
                    label="BTU"
                    outlined
                    v-model="zone.btu"
                    v-show="zone.name && zone.unitType"
                    dense
                  ></v-select>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <v-btn text class="mr-2" @click="e1 = 1">Back</v-btn>
              <v-btn color="primary" @click="findSystem">Continue</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-stepper-content>
      <v-stepper-content
        step="3"
        style="overflow-y: scroll; max-height: 700px; background: #f4f4f4"
      >
        <div class="text-center mt-5">
          <v-btn color="primary" @click="e1 = 2">back</v-btn>
        </div>
        <v-row>
          <v-col cols="12" sm="6" md="6" class="py-2 mt-5" offset-sm="3">
            <v-select
              v-if="filters.performance.length > 1"
              v-model="filters.performanceActive"
              :items="filters.performance"
              item-text="text"
              item-value="value"
              attach
              chips
              label="Filter by Performance"
              multiple
              @change="findSystem"
            ></v-select>
            <v-select
              v-if="filters.indoorProductLine.length > 1"
              v-model="filters.indoorProductLineActive"
              :items="filters.indoorProductLine"
              item-text="text"
              item-value="value"
              attach
              chips
              label="Filter by Wall Mount"
              multiple
              @change="findSystem"
            ></v-select>
            <v-select
              v-if="filters.outdoorProductLine.length > 1"
              v-model="filters.outdoorProductLineActive"
              :items="filters.outdoorProductLine"
              item-text="text"
              item-value="value"
              attach
              chips
              label="Filter by Outdoor Product Line"
              multiple
              @change="findSystem"
            ></v-select>
            <v-select
              v-if="filters.outdoorSize.length > 1"
              v-model="filters.outdoorSizeActive"
              :items="filters.outdoorSize"
              item-text="text"
              item-value="value"
              attach
              chips
              label="Filter by Outdoor Size"
              multiple
              @change="findSystem"
            ></v-select>
            <div class="text-center mt-3">Systems Found: {{systems.length}}</div>
            <v-alert
              class="mt-5"
              border="top"
              colored-border
              color="warning"
              type="error"
              elevation="1"
              v-show="!systems.length"
            >
              {{noResultsMessage}}
            </v-alert>
          </v-col>
        </v-row>
        <div v-for="system in systems" :key="system.key">
          <MultiZoneProductCard :system="system"></MultiZoneProductCard>
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import fb from "@/main";
import { groupBy, intersection } from "lodash";
import multiZoneProducts from "@/data/multiZoneBuilder.json";
import MultiZoneProductCard from "@/components/MultiZoneProductCard";
import multiZoneBrochure from "@/data/multiZoneBrochure.json";

export default {
  name: "MultiZone",
  components: { MultiZoneProductCard },
  data() {
    return {
      e1: 1,
      type: null,
      unitOptions: ['Wall Mount', 'Ceiling Cassette', 'Universal Floor/Ceiling', 'Slim Duct', 'Mini Floor Console'],
      btuOptions: {
        'Wall Mount': [9000, 12000, 18000, 24000],
        'Ceiling Cassette': [12000, 18000, 24000],
        'Universal Floor/Ceiling': [9000, 12000, 18000, 24000],
        'Slim Duct': [9000, 12000, 18000, 24000],
        'Mini Floor Console': [9000, 12000, 18000]
      },
      zoneNames: ['Basement', 'Bedroom', 'Dining Room', 'Kitchen', 'Living Room', 'Office', 'Room', 'Zone'],
      zoneOptions: [2, 3, 4, 5, 6, 7, 8, 9],
      zoneCount: null,
      zones: [],
      systems: [],
      noResultsMessage: "Sorry, we don't have a system that supports the combination of your selected indoor capacities. Please revise and try again.",
      filters: {
        performance: [],
        performanceActive: [],
        outdoorProductLine: [],
        outdoorProductLineActive: [],
        outdoorSize: [],
        outdoorSizeActive: [],
        indoorProductLine: [],
        indoorProductLineActive: [],
      },
    };
  },
  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    const systemId = urlParams.get('systemId');
    if (systemId) {
      const doc = await fb.db
        .collection("systems")
        .doc(systemId)
        .get();
      const system = doc.data();
      const zones = system.system.indoors.map((indoor) => {return indoor.zone;});
      if (system.type == 'Multi Zone') {
        this.zones = zones;
        this.zoneCount = zones.length;
        this.e1 = 2;
      }
    }
  },
  computed: {
  },
  methods: {
    toggleFilter(key) {
      if (this.filters.active.includes(key)) {
        const index = this.filters.active.indexOf(key);
        this.filters.active.splice(index, 1);
      } else {
        this.filters.active.push(key);
      }
      this.findSystem();
    },
    createZones() {
      const diff = this.zoneCount - this.zones.length;

      if (diff > 0 && this.zones.length === 0) {
        this.zones = Array.from({ length: this.zoneCount }, () => {
          const zone = {name: "", btu: null, unitType: null};
          return zone;
        });
      }
      else if (diff > 0) {
        for (let i = 0; i < diff; i++) {
          const zone = {name: "", btu: null, unitType: null};
          this.zones.push(zone);
        }
      }
      else if (diff < 0) {
        this.zones = this.zones.slice(0, this.zoneCount);
      }

      if (this.zones.length) {
        this.e1 = 2;
      }
    },
    setIndoorFilters(systems, filterType) {
      const indoorModels = [];
      systems.map((s) => {return s.indoors.forEach((ind) => {indoorModels.push(ind.model)})});
      const filterList = multiZoneBrochure
        .filter((m) => {return indoorModels.includes(m["Indoor Model No."]);})
        .map((m) => {
          let text = '';
          if (filterType === 'indoorProductLine') {
            text = m['Name'];
          }
          return {
            value: `${m["Indoor Model No."]}`,
            text: text
          };
        }); 
      const filterListFinal = [];
      const filterListGrouped = groupBy(filterList, 'text');
      Object.keys(filterListGrouped).forEach((key) => {
        if (key != 'undefined') {
          filterListFinal.push(
            {
              value: filterListGrouped[key].map((fl) => {return fl.value}).join(','),
              text: key
            }
          )
        }
      });
      this.filters[filterType] = filterListFinal;

      const models = [];
      this.filters[filterType + 'Active'].forEach((fa) => {fa.split(',').forEach((fas) => {models.push(fas)})})
      return models;
    },
    setOutdoorFilters(systems, filterType) {
      const outdoorModels = systems.map((s) => {return s.outdoor});
      const filterList = multiZoneBrochure
        .filter((m) => {return outdoorModels.includes(m["Outdoor Model No."]);})
        .map((m) => {
          let text = '';
          if (filterType === 'performance') {
            text = `Cooling Range (°F): ${m['Cooling Range (°F)']} | Heating Range (°F): ${m['Heating Range (°F)']}`;
          }
          if (filterType === 'outdoorProductLine') {
            text = m['Name'];
          }
          if (filterType === 'outdoorSize') {
            text = m['Nominal Capacity (Btu)'];
          }
          return {
            value: `${m["Outdoor Model No."]}`,
            text: text
          };
        }); 
      const filterListFinal = [];
      const filterListGrouped = groupBy(filterList, 'text');
      Object.keys(filterListGrouped).forEach((key) => {
        if (key != 'undefined') {
          filterListFinal.push(
            {
              value: filterListGrouped[key].map((fl) => {return fl.value}).join(','),
              text: key
            }
          )
        }
      });
      this.filters[filterType] = filterListFinal;

      const models = [];
      this.filters[filterType + 'Active'].forEach((fa) => {fa.split(',').forEach((fas) => {models.push(fas)})})
      return models;
    },
    async findSystem() {
      const haultMsg = (this.zones.find((z) => !z.btu)) ? 'Please fill out all empty fields.' : null;

      if (haultMsg) {
        alert(haultMsg)
        return
      }

      const btus = (this.zones.map((z) => {return z.btu/1000})).sort((a, b) => {return a-b});

      const outdoors = [];
      Object.keys(multiZoneProducts.btus).forEach((key) => {
        const matches = multiZoneProducts.btus[key].filter((m) => {return m.length === parseInt(this.zoneCount)});
        if (matches.length) {
          const found = matches.find((m) => {
            for (let i = 0; i < m.length; i++) {
              if (m[i] != btus[i]) {
                return false
              }
            }
            return true;
          })
          if (found) {
            outdoors.push(key);
          }
        }
      })

      const indoors = [];
      multiZoneProducts.combinations.forEach((combination, i) => {
        indoors[i] = [];
        this.zones.forEach((z) => {
          const btu = (z.btu / 1000).toString().padStart(2, "0") + 'HP';
          const model = combination[z.unitType].find((m) => {return m.includes(btu)});
          if (model) {
            indoors[i].push({model, zone: z});
          }
        })
      })

      const systems = [];
      const processedSystems = [];
      indoors.forEach((indoor) => {
        outdoors.forEach((outdoor) => {
          const indoorModels = indoor.map((ind) => {return ind.model;})
          const systemKey = `${outdoor}${indoorModels.join('')}`;
          const renderKey = `${outdoor}${indoorModels.join('')}-${new Date().getTime()}`;
          if (!processedSystems.includes(systemKey) && this.zones.length === indoorModels.length) {
            systems.push({'outdoor': outdoor, 'indoors': indoor, 'key': renderKey});
            processedSystems.push(systemKey);
          }
        });
      })

      const performanceModels = this.setOutdoorFilters(systems, 'performance');
      const outdoorProductLineModels = this.setOutdoorFilters(systems, 'outdoorProductLine');
      const outdoorSizeModels = this.setOutdoorFilters(systems, 'outdoorSize');
      const indoorProductLineModels = this.setIndoorFilters(systems, 'indoorProductLine')
      const filteredSystems = systems.filter((sys) => {
        return (!performanceModels.length || performanceModels.includes(sys.outdoor))
          && (!outdoorProductLineModels.length || outdoorProductLineModels.includes(sys.outdoor))
          && (!outdoorSizeModels.length || outdoorSizeModels.includes(sys.outdoor))
          && (!indoorProductLineModels.length || intersection(indoorProductLineModels, sys.indoors.map((ind) => {return ind.model})).length)
      });

      this.systems = filteredSystems;
      this.e1 = 3;
    },
  },
};
</script>
