<template>
  <v-main class="login-bg">
    <v-container fluid grid-list-md>
      <div class="text-right white--text" style="width:100%;">
        Not registered?
        <v-btn color="primary" to="/signup">Sign Up</v-btn>
      </div>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-12 signup-card py-4 my-4">
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12>
                  <div>
                    <div class="headline text-center">
                      <img src="../assets/img/gree-logo.png" width="250" />
                    </div>
                    <div class="text-center text-overline mb-2" style="margin-top:-25px;">
                      LOG IN
                    </div>
                    <h2 class="text-center font-weight-regular">
                      SYSTEM BUILDER
                    </h2>
                  </div>

                  <br />
                  <v-form>
                    <v-text-field
                      prepend-icon="person"
                      name="email"
                      label="Email"
                      type="email"
                      v-model="email"
                    ></v-text-field>
                    <v-text-field
                      prepend-icon="lock"
                      name="password"
                      label="Password"
                      id="password"
                      type="password"
                      v-model="password"
                    ></v-text-field>
                    <p class="text-xs-right">
                      <a @click="forgotPassword">Forgot your password?</a>
                    </p>
                    <v-btn @click="login" block dark color="primary" large
                      >Sign In</v-btn
                    >
                  </v-form>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import fb from "@/main";
import image from "@/assets/img/bg.jpg";
export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      image,
    };
  },
  methods: {
    login: async function (e) {
      e.preventDefault();
      try {
        await fb.auth.signInWithEmailAndPassword(this.email, this.password);
        this.$router.push("/");
      } catch (e) {
        alert(e.message);
        console.log(e);
      }
    },
    forgotPassword: function () {
      const email = prompt("Enter your email to request a password reset.");
      if (email) {
        fb.auth
          .sendPasswordResetEmail(email)
          .then(function () {
            alert(
              "Email Sent with password reset link. Check your email inbox."
            );
          })
          .catch(function () {
            console.log("error sending password reset email.");
          });
      }
    },
  },
};
</script>

<style scoped>
.signup-card {
  padding: 15px;
}
.login-bg {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url("~@/assets/img/bg.jpg");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
}
</style>
