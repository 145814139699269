<template>
  <v-container grid-list-lg>
    <!-- CUSTOMER INFO -->
    <v-row>
      <v-col cols="12" sm="6" offset-sm="3" class="pb-0">
        <div class="text-center" v-if="type">
          <h2 v-if="type">{{ type }} Builder</h2>
          <v-alert
            outlined
            icon="edit"
            color="warning"
            dense
            text
            max-width="340"
            class="mt-3 mx-auto text-left"
            v-show="systemId"
          >You are editing an existing system.</v-alert>
          <div class="mt-2">
            <v-btn
              class="mr-3"
              rounded
              x-small
              outlined
              v-if="type"
              @click="type = null"
              >Edit System Type</v-btn
            >
            <v-btn
              class=""
              rounded
              x-small
              outlined
              color="red"
              v-if="type"
              :to="`/project/${projectId}`"
              >Cancel</v-btn
            >
          </div>
        </div>
        <v-select
          v-else
          outlined
          :items="typeOptions"
          v-model="type"
          label="Select the type of system."
        ></v-select>
      </v-col>
      <v-col cols="12" sm="12" class="pb-0">
        <SingleZone v-if="type == 'Single Zone'"></SingleZone>
        <MultiZone v-if="type == 'Multi Zone'"></MultiZone>
        <MultiProStandard v-if="type == 'MultiPRO Standard'"></MultiProStandard>
        <MultiProUltra v-if="type == 'MultiPRO Ultra'"></MultiProUltra>
        <CentralAir v-if="type == 'Central Air'"></CentralAir>
        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
          {{ snackText }}
          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import fb from "@/main";
import { mapGetters } from "vuex";
import SingleZone from "@/components/SingleZone.vue";
import MultiZone from "@/components/MultiZone.vue";
import MultiProStandard from "@/components/MultiProStandard.vue";
import MultiProUltra from "@/components/MultiProUltra.vue";
import CentralAir from "@/components/CentralAir.vue";

export default {
  name: "Builder",
  components: { SingleZone, MultiZone, MultiProStandard, MultiProUltra, CentralAir },
  data() {
    return {
      snack: false,
      snackColor: "",
      snackText: "",
      typeOptions: ["Single Zone", "Multi Zone", "MultiPRO Standard", "MultiPRO Ultra", "Central Air"],
      type: null,
      systemId: null,
      projectId: null
    };
  },
  async created() {
    this.projectId = this.$route.params.projectId;
    const urlParams = new URLSearchParams(window.location.search);
    const systemId = urlParams.get('systemId');
    if (systemId) {
      const doc = await fb.db
        .collection("systems")
        .doc(systemId)
        .get();
      const system = doc.data();
      this.systemId = systemId;
      this.type = system.type;
    }
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {},
};
</script>
