<template>
  <v-main class="login-bg">
    <v-container fluid grid-list-md>
      <div class="text-right white--text" style="width:100%;">
        Already registered?
        <v-btn color="primary" to="/login">Log In</v-btn>
      </div>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-12 signup-card py-4 my-4">
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12>
                  <div>
                    <div class="headline text-center">
                      <img src="../assets/img/gree-logo.png" width="250" />
                    </div>
                    <div class="text-center text-overline mb-2" style="margin-top:-25px;">
                      SIGN UP
                    </div>
                    <h2 class="text-center font-weight-regular">
                      SYSTEM BUILDER
                    </h2>
                  </div>

                  <br />
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-layout row wrap>
                      <v-flex xs12 sm6>
                        <v-text-field
                          v-model="firstName"
                          label="First Name"
                          :rules="[(v) => !!v || 'First Name is required']"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6>
                        <v-text-field
                          v-model="lastName"
                          label="Last Name"
                          :rules="[(v) => !!v || 'Last Name is required']"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          v-model="phone"
                          v-mask="'(###) ###-####'"
                          label="Phone number"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <v-select
                          :items="[
                            'Contractor',
                            'Distributor',
                            'Homeowner',
                            'Other',
                          ]"
                          v-model="type"
                          label="Choose Option"
                          :rules="[(v) => !!v || 'This is required']"
                          required
                        ></v-select>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          v-model="email"
                          label="Email"
                          required
                          :rules="emailRules"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          v-model="password"
                          :rules="passwordRules"
                          label="Create Password"
                          required
                          :append-icon="
                            passwordType ? 'visibility' : 'visibility_off'
                          "
                          @click:append="() => (passwordType = !passwordType)"
                          :type="passwordType ? 'password' : 'text'"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                      </v-flex>
                        <v-checkbox
                        v-model="newsletter"
                        label="Opt-in to receive news and updates from GREE."
                        hide-details
                        ></v-checkbox>
                      </v-layout>
                    <br />
                    <div class="grey--text">
                      By creating an account, you are agreeing to our <a href="/terms-and-conditions" target="_blank">Terms & Conditions</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>
                    </div>
                    <br />
                    <v-btn
                      :disabled="loading"
                      :loading="loading"
                      @click="submit"
                      block
                      color="primary"
                      large
                      >Create Account</v-btn
                    >
                  </v-form>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import fb from "@/main";
import image from "@/assets/img/bg.jpg";
import { mask } from "vue-the-mask";
export default {
  name: "login",
  directives: {
    mask,
  },
  data() {
    return {
      passwordType: "password",
      loading: false,
      image,
      type: "",
      firstName: "",
      lastName: "",
      valid: true,
      username: "",
      email: "",
      phone: "",
      newsletter: true,
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      password: "",
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length > 5) || "password must be more than 5 characters",
      ],
      snackbar: false,
    };
  },
  methods: {
    async submit(e) {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          this.email = this.email.toLowerCase().trim();
          const authData = await fb.auth.createUserWithEmailAndPassword(
            this.email,
            this.password
          );
          if (authData.user) {
            const userData = {
              firstName: this.firstName,
              lastName: this.lastName,
              phone: this.phone,
              email: this.email,
              newsletter: this.newsletter,
              uid: authData.user.uid,
              createDate: fb.timestamp,
            };
            fb.db.doc(`users/${authData.user.uid}`).set(userData);
            this.$store.commit("cacheUser", userData);
            await authData.user.sendEmailVerification();
            this.loading = false;
            this.$router.push("/verification");
          }
        } catch (error) {
          this.loading = false;
          console.log(error);
          alert(error.message);
        }
      }
      e.preventDefault();
    },
  },
};
</script>

<style scoped>
.signup-card {
  padding: 15px;
}
.login-bg {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url("~@/assets/img/bg.jpg");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
}
</style>
