<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1">Options</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 2" step="2"> Selection </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1" style="overflow-y: scroll; max-height: 700px">
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="6" md="6" class="py-2" offset-sm="3">
              <v-select
                :items="voltageOptions"
                name="voltage"
                label="Voltage"
                outlined
                v-model="zone.voltage"
              ></v-select>
              <v-select
                :items="Object.keys(btuOptions[zone.voltage])"
                name="btu"
                label="BTU"
                outlined
                v-model="zone.btu"
                v-if="zone.voltage"
              ></v-select>
              <v-select
                :items="btuOptions[zone.voltage][zone.btu]"
                name="btu"
                label="Unit Type"
                outlined
                v-model="zone.unitType"
                v-if="zone.btu"
              ></v-select>
              <div class="text-center">
                <v-btn color="primary" @click="findSystem()"> Continue </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-stepper-content>

      <v-stepper-content
        step="2"
        style="overflow-y: scroll; max-height: 700px; background: #f4f4f4"
      >
        <div class="text-center mt-5">
          <v-btn color="primary" @click="e1 = 1">back</v-btn>
        </div>
        <v-row>
          <v-col cols="12" sm="6" md="6" class="py-2 mt-5" offset-sm="3">
            <v-select
              v-if="filters.energy.length > 1"
              v-model="filters.energyActive"
              :items="filters.energy"
              item-text="text"
              item-value="value"
              attach
              chips
              label="Filter by Energy Efficiency"
              multiple
              @change="findSystem"
            ></v-select>
            <v-select
              v-if="filters.performance.length > 1"
              v-model="filters.performanceActive"
              :items="filters.performance"
              item-text="text"
              item-value="value"
              attach
              chips
              label="Filter by Performance"
              multiple
              @change="findSystem"
            ></v-select>
            <div class="text-center mt-3">Systems Found: {{systems.length}}</div>
            <v-alert
              class="mt-5"
              border="top"
              colored-border
              color="warning"
              type="error"
              elevation="1"
              v-show="!systems.length"
            >
              {{noResultsMessage}}
            </v-alert>
          </v-col>
        </v-row>
        <div v-for="system in systems" :key="system.model">
          <SingleZoneProductCard :system="system"></SingleZoneProductCard>
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import fb from "@/main";
import { groupBy } from "lodash";
import SingleZoneProductCard from "@/components/SingleZoneProductCard";
import singleZoneProducts from "@/data/singleZoneBuilder.json";
import singleZoneBrochure from "@/data/singleZoneBrochure.json";

export default {
  name: "SingleZone",
  components: { SingleZoneProductCard },
  data() {
    return {
      e1: 1,
      voltageOptions: ["115", "208/230"],
      btuOptions: {
        115: { 9000: ["Wall Mount"], 12000: ["Wall Mount"] },
        "208/230": {
          9000: ["Wall Mount"],
          12000: [
            "Wall Mount",
            "Ceiling Cassette",
            "Universal Floor/Ceiling",
            "Slim Duct",
          ],
          18000: [
            "Wall Mount",
            "Ceiling Cassette",
            "Universal Floor/Ceiling",
            "Slim Duct",
          ],
          24000: [
            "Wall Mount",
            "Ceiling Cassette",
            "Universal Floor/Ceiling",
            "Slim Duct",
          ],
          30000: ["Wall Mount", "Universal Floor/Ceiling", "Slim Duct"],
          36000: ["Wall Mount"],
        },
      },
      zone: {
        btu: null,
        unitType: null,
        voltage: null,
      },
      systems: [],
      noResultsMessage: "Sorry, we don't have a system that supports your selected indoor capacity. Please revise and try again.",
      filters: {
        energy: [],
        energyActive: [],
        performance: [],
        performanceActive: []
      },
    };
  },
  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    const systemId = urlParams.get('systemId');
    if (systemId) {
      const doc = await fb.db
        .collection("systems")
        .doc(systemId)
        .get();
      const system = doc.data();
      if (system.type == 'Single Zone') {
        this.zone = system.system.zone;
      }
    }
  },
  methods: {
    toggleFilter(key) {
      if (this.filters.active.includes(key)) {
        const index = this.filters.active.indexOf(key);
        this.filters.active.splice(index, 1);
      } else {
        this.filters.active.push(key);
      }
      this.findSystem();
    },
    setIndoorFilters(systems, filterType) {
      const filterList = singleZoneBrochure
        .filter((m) => {return systems.includes(m["Indoor Model No."]);})
        .map((m) => {
          let text = '';
          if (filterType === 'energy') {
            text = `SEER / EER: ${m['SEER / EER']} - HSPF / COP: ${m['HSPF / COP']}`;
          }
          if (filterType === 'performance') {
            text = `Cooling Range (°F): ${m['Cooling Range (°F)']} | Heating Range (°F): ${m['Heating Range (°F)']}`;
          }
          return {
            value: `${m["Indoor Model No."]}`,
            text: text
          };
        });
      const filterListFinal = [];
      const filterListGrouped = groupBy(filterList, 'text');
      Object.keys(filterListGrouped).forEach((key) => {
        if (key != 'undefined') {
          filterListFinal.push(
            {
              value: filterListGrouped[key].map((fl) => {return fl.value}).join(','),
              text: key
            }
          )
        }
      });
      this.filters[filterType] = filterListFinal;

      const models = [];
      this.filters[filterType + 'Active'].forEach((fa) => {fa.split(',').forEach((fas) => {models.push(fas)})})
      return models;
    },
    findSystem() {
      if (!this.zone.unitType) {
        alert('Please fill out all empty fields.')
        return;
      }

      const btu =
        (this.zone.btu / 1000).toString().padStart(2, "0") + "HP";
      const voltage = this.zone.voltage.includes(115) ? "115" : "230";
      const systems = singleZoneProducts[this.zone.unitType].filter(
        (s) => {
          return s.includes(btu) && s.includes(voltage);
        }
      );

      const energyModels = this.setIndoorFilters(systems, 'energy');
      const performanceModels = this.setIndoorFilters(systems, 'performance');
      const filteredSystems = systems.filter((model) => {
        return (!energyModels.length || energyModels.includes(model))
        && (!performanceModels.length || performanceModels.includes(model))
      });

      this.systems = filteredSystems.map((model) => {return {model: model, zone: this.zone}});
      this.e1 = 2;
    },
  },
};
</script>
